/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { Fragment } from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { contentId, contentTypeId } from '@u/contentful'
import { Components, Options } from '@h/contentful'
import connectEntity from '@h/connect-entity'
import ModuleMarker from '@h/module-marker'
import ModuleWrapper from '@h/module-wrapper'
import DontHydrate from '@h/dont-hydrate'

export const moduleEntity = (module, extraData = {}) => {
  const props = { entityId: contentId(module), ...extraData }
  return props ? { props } : {}
}

const Module = ({ props, contentType, hydrate, ...mod }) => {
  const content = (
    <Fragment>
      <ModuleMarker entity={props} />
      <If condition={mod.tag}>
        <mod.tag {...props} />
      </If>
    </Fragment>
  )

  // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
  const WrapedContent = hydrate ? content : <DontHydrate>{content}</DontHydrate>

  return (
    <If condition={mod.tag}>
      <Choose>
        {/* Temporary until deprecated spacing options are removed */}
        <When condition={mod.wrapperConfig && mod.wrapperConfig.noWrapper}>{WrapedContent}</When>
        <Otherwise>
          <ModuleWrapper {...mod.wrapperConfig}>{WrapedContent}</ModuleWrapper>
        </Otherwise>
      </Choose>
    </If>
  )
}

const ConnectedModule = connectEntity((state, props) => {
  const contentType = contentTypeId(props)
  const component = props.tag ? Components[props.tag] : Components[contentType]
  // component can be undefined!
  const tag = (component && component.component) || component
  const wrapperConfig = component && component.wrapperConfig
  const hydrate = typeof component?.hydrate !== 'undefined' ? component?.hydrate : true

  return { tag, contentType, props, wrapperConfig, hydrate }
})(Module)

export const ContentfulModule = ({ props: { entity, entityId, tag, ...extraData }, className }) => (
  <ConnectedModule
    className={className}
    entityId={entityId}
    entity={entity}
    tag={tag}
    {...extraData}
  />
)

export const ContentfulModules = ({ modules = [], className, ...extraData }) => (
  <For each='mod' of={modules} index='idx'>
    <ContentfulModule key={idx} {...moduleEntity(mod)} className={className} {...extraData} />
  </For>
)

export const ModulesToReactComponents = (content, extraData, options) => {
  const components = documentToReactComponents({ content }, Options(extraData, options))
  return (
    <For each='el' index='key' of={components}>
      <Choose key={key}>
        <When condition={el.type}>{{ ...el, props: { ...el.props, key } }}</When>
        <Otherwise>
          <ContentfulModule key={key} {...el} />
        </Otherwise>
      </Choose>
    </For>
  )
}

export const ModulesToReactComponentsComponent = React.memo(({ content, extraData, options }) => {
  const components = documentToReactComponents({ content }, Options(extraData, options))
  return (
    <For each='el' index='key' of={components}>
      <Choose key={key}>
        <When condition={el.type}>{{ ...el, props: { ...el.props, key } }}</When>
        <Otherwise>
          <ContentfulModule key={key} {...el} />
        </Otherwise>
      </Choose>
    </For>
  )
})
