/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'

import sw from '@u/switch'

const TrustyleAuthor = loadable(() => import('@c/trustyle-author'))
const EeveeAuthor = loadable(() => import('@c/eevee-author'))

// We should migrate this to always use the trustyle Author comp
export default props => {
  const config = useSelector(state => state?.app?.meta?.authorConfig) || {}

  return sw({
    trustyleAuthor: props => <TrustyleAuthor author={props} />,
    default: props => <EeveeAuthor {...props} />,
  })(config.component, props)
}
