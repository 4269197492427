/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import { connect } from 'react-redux'
import { host } from '@r/app-selectors'

import { parseTrackingUrl } from '@u/tracking'

const sameHostConnector = (s, { href }) => {
  try {
    const sameHost = host(s) === new URL(href).host

    return sameHost
      ? {}
      : {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
  } catch (e) {
    return {}
  }
}

export const LinkWrapper = connect(sameHostConnector)(({ className, children, href, ...props }) => {
  const [trimmedHref, dataAttributes] = parseTrackingUrl(href)

  return (
    <a href={trimmedHref} {...dataAttributes} {...props} className={className}>
      {children}
    </a>
  )
})

export const Link = connect(sameHostConnector)(({ children, variant, href = '', ...props }) => {
  const [trimmedHref, dataAttributes] = parseTrackingUrl(href)

  return (
    <Styled.a
      className={variant}
      href={href.startsWith('#') ? trimmedHref.toLowerCase() : trimmedHref}
      {...dataAttributes}
      {...props}
      sx={{ variant: `styles.a.${variant}` }}
    >
      <span sx={{ variant: 'elements.link-with-chevron' }}>{children}</span>
    </Styled.a>
  )
})
