/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { ModulesToReactComponents } from '@h/modules'
import { smartContainer } from '@h/smart-container'
import { Container } from '@uswitch/trustyle.flex-grid'
import { PaletteProvider } from '@uswitch/trustyle-utils.palette'
import ModuleWrapper, { ModuleWrapperContext } from '@h/module-wrapper'

export const RichPage = ({ content: document, options = {} }, extraData = {}) => {
  const { includes, ...rest } = extraData
  const { theme = {} } = useThemeUI()
  const contentCols = theme?.container?.contentCols || 12
  const contentSpan = theme?.container?.contentSpan || 12

  return (
    <div sx={options?.styles || {}}>
      <For each='group' index='key' of={smartContainer(document.content, includes)}>
        <PaletteProvider value={group.palette} key={key}>
          <ModuleWrapperContext.Provider
            value={{
              alreadyWrapped: false,
              spacingTop: group.spacingOverride && group.spacingOverride.top,
              spacingBottom: group.spacingOverride && group.spacingOverride.bottom,
            }}
          >
            <Choose>
              <When condition={group.container}>
                <ModuleWrapper useContainer={false} spacingTop='md'>
                  <Container
                    sx={{
                      variant: [
                        'layout.container.sm',
                        'layout.container.md',
                        'layout.container.content',
                      ],
                    }}
                    cols={contentCols}
                    span={contentSpan}
                  >
                    <div sx={{ variant: 'layout.container.content.contentStyle' }}>
                      {ModulesToReactComponents(group, { ...rest })}
                    </div>
                  </Container>
                </ModuleWrapper>
              </When>
              <Otherwise>{ModulesToReactComponents(group, { ...rest })}</Otherwise>
            </Choose>
          </ModuleWrapperContext.Provider>
        </PaletteProvider>
      </For>
    </div>
  )
}
