/** @jsx jsx */
import { jsx } from 'theme-ui'

const Container = ({ className = '', variant, children, sx = {} }) => (
  <div
    className={className}
    sx={{
      variant: variant || ['layout.container.sm', 'layout.container.md', 'layout.container.lg'],
      ...sx,
    }}
  >
    {children}
  </div>
)
export default Container
