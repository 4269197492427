import connect from '@h/connect-redux'
import { getModuleFromIncludes, includes } from '@r/contentful-selectors'

import { contentId } from '@u/contentful'
import getWindow from '@u/get-window'

/**
 * @typedef {Function} ContentfulStateToProps
 * A function that accepts a Contentful module from the state and maps the data into an object of
 * component properties.
 *
 * @param {Object} state - The Redux state.
 * @param {Object} props - The properties to map.
 * @returns {Object}
 */

/**
 * A connect-entity function. This function takes a mapStateToProps function with the same
 * signature as the Redux connect function. It reads the `id` property off of the component to know
 * what module to fetch from the contentful state before mapping the contentful module state over
 * the supplied mapStateToProps function.
 *
 * The result of this function is a contentful connected React component.
 *
 * @param {ContentfulStateToProps} [mapStateToProps] - The contentful state map function.
 * @returns {Function}
 */

const defaultMapStateToProps = (state, props) => props || {}

const connectEntity = (mapStateToProps = defaultMapStateToProps) => component => {
  const safeComponent = ({ _error, ...props }) => {
    if (_error && getWindow().Sentry) getWindow().Sentry.captureException(_error)
    if (_error) console.log('[ERROR] connect entity', _error)
    return _error ? null : component(props)
  }

  const rrMapStateToProps = (state, { entityId, entity, ...extraData }) => {
    const id = entityId || contentId(entity)
    const { fields, sys } = getModuleFromIncludes(includes(state), id)

    const props = {
      ...fields,
      ...extraData,
      sys,
    }

    return !fields
      ? { _error: `ENOENTITYID: Could not find entity in includes for ${id}` }
      : mapStateToProps(state, props)
  }

  return connect(rrMapStateToProps)(safeComponent)
}

export default connectEntity
