/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { createContext, useContext } from 'react'
import { Container } from '@uswitch/trustyle.flex-grid'
import { Palette } from '@uswitch/trustyle-utils.palette'

export const ModuleWrapperContext = createContext({
  alreadyWrapped: false,
  childSpacing: false,
  spacingTop: 'lg',
  spacingBottom: 'lg',
})

const getFirstDefined = (...args) => args.find(arg => typeof arg !== 'undefined')

const nonZero = x => (x === 0 || x === '0' || x === '0px' ? 0.05 : x)

export default ({
  // useContainer should only ever be needed when a module is full width:
  // the old contentful hack for when something is sometimes a module and
  // sometimes an ordinary component is automatically handled now.
  useContainer = true,
  childSpacing = false,
  spacingTop: spacingTopProp,
  spacingBottom: spacingBottomProp,
  children,
  className,
}) => {
  const wrapperContext = useContext(ModuleWrapperContext)

  const { theme = {} } = useThemeUI()
  const contentCols = theme?.container?.contentCols || 12
  const contentSpan = theme?.container?.contentSpan || 12

  // const fallback = wrapperContext.alreadyWrapped ? 0 : 'lg'
  const fallback = 0
  // nonZero to prevent margin collapse
  const spacingTop = nonZero(getFirstDefined(wrapperContext.spacingTop, spacingTopProp, fallback))
  const spacingBottom = nonZero(
    getFirstDefined(wrapperContext.spacingBottom, spacingBottomProp, fallback),
  )

  const spacingSx = { paddingTop: spacingTop, paddingBottom: spacingBottom }

  const contents = (
    <Choose>
      <When condition={useContainer && !wrapperContext.alreadyWrapped}>
        <Container
          cols={contentCols}
          span={contentSpan}
          sx={{ variant: 'layout.container.moduleWrapper' }}
        >
          {children}
        </Container>
      </When>
      <When condition={spacingTop || spacingBottom}>{children}</When>
      <Otherwise>{children}</Otherwise>
    </Choose>
  )

  return (
    <ModuleWrapperContext.Provider value={{ alreadyWrapped: true, childSpacing }}>
      <Choose>
        <When condition={wrapperContext.alreadyWrapped && wrapperContext.childSpacing}>
          <div sx={spacingSx} className={className}>
            {contents}
          </div>
        </When>
        <When condition={wrapperContext.alreadyWrapped}>{contents}</When>
        <Otherwise>
          <Palette
            as='div'
            sx={{ width: '100%', ...spacingSx }}
            px={{ backgroundColor: 'backgroundColor' }}
            className={className}
          >
            {contents}
          </Palette>
        </Otherwise>
      </Choose>
    </ModuleWrapperContext.Provider>
  )
}
