/**
 * Converts strings into kebab case.
 *
 * @param {string} str - The string to convert.
 * @returns {string}
 */
const toXCase = _case => str =>
  str &&
  str.trim() &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join(_case)

export const toKebabCase = toXCase('-')
export const toSnakeCase = toXCase('_')

// Converts strings into camel case.
export const toCamelCase = (str = '') => {
  const s =
    str &&
    str.trim() &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join('')

  return s.slice(0, 1).toLowerCase() + s.slice(1)
}
