import React from 'react'
import { Helmet } from 'react-helmet-async'

const ArticleSchema = ({
  context,
  type,
  headline,
  description,
  image,
  datePublished,
  dateModified,
  author,
  publisher,
  mainEntityOfPage,
}) => {
  if (!context) return <></>

  const schema = JSON.stringify({
    '@context': context,
    '@type': type,
    headline,
    description,
    image,
    datePublished,
    dateModified,
    author,
    publisher,
    mainEntityOfPage,
  })

  return (
    <Helmet>
      <script type='application/ld+json'>{schema}</script>
    </Helmet>
  )
}

export default ArticleSchema
