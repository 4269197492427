import { contentId } from '@u/contentful'

export const state = (s = {}) => s.contentful || {}

export const breadcrumb = s => state(s).breadcrumb

const payload = (s = {}) => state(s).ctx || {}

export const findEntityBySysId = (s = {}, id) =>
  Object.values(includes(s)).find(entity => contentId(entity) === id) || {}

export const findEntityFieldsBySysId = (s = {}, id) => findEntityBySysId(s, id).fields || {}

export const findEntitiesFields = (s = {}, entries) =>
  entries.map(entry => findEntityBySysId(s, contentId(entry)).fields || {})

export const findEntities = (s = {}, entries) =>
  entries.map(entry => findEntityBySysId(s, contentId(entry)))

export const includes = (s = {}) => payload(s).includes || {}

export const getModuleFromIncludes = (includes, entityId) => includes[entityId] || {}

const dates = (s = {}) => state(s).dates
export const updatedAt = (s = {}) => dates(s).updated
export const createdAt = (s = {}) => dates(s).created
