import React from 'react'
import sw from '@u/switch'
import loadable from '@loadable/component'

const Buttons = loadable(() => import('@m/collection-buttons'))
const Carousel = loadable(() => import('@m/collection-carousel'))
const FixedSizeGrid = loadable(() => import('@m/fixed-size-grid'))
const Footer = loadable(() => import('@m/collection-footer'))
const Grid = loadable(() => import('@m/collection-grid'))
const ImageGrid = loadable(() => import('@m/collection-image-grid'))
const Logos = loadable(() => import('@m/collection-image-logo'))
const TwoColContent = loadable(() => import('@m/collection-twocol-content'))
const Tabs = loadable(() => import('@m/collection-tabs'))
const Sidebar = loadable(() => import('@m/collection-sidebar'))

export default ({ layout, ...props }) =>
  sw({
    'divided-grid': () => <Grid {...props} divided />,
    'fixed-size': () => <FixedSizeGrid {...props} />,
    'image-grid': () => <ImageGrid {...props} />,
    'two-col-content': () => <TwoColContent {...props} />,
    buttons: () => <Buttons {...props} />,
    carousel: () => <Carousel {...props} />,
    footer: () => <Footer {...props} />,
    grid: () => <Grid {...props} />,
    logos: () => <Logos {...props} />,
    tabs: () => <Tabs {...props} />,
    sidebar: () => <Sidebar {...props} />,
    default: () => null,
  })(layout)
