/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import { BLOCKS as B, INLINES as I, MARKS as M } from '@contentful/rich-text-types'
import loadable from '@loadable/component'
import { Link } from '@e/link'
import { moduleEntity } from '@h/modules'
import { toKebabCase } from '@u/string'
import Filter from '@h/filtered'
import { Palette } from '@uswitch/trustyle-utils.palette'
import DontHydrate from '@h/dont-hydrate'
import HeroModule from '@m/hero-module.connected'
import Author from '@c/author'
import collection from '@m/collection'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

export const Components = {
  starRating: loadable(() => import('@e/star-rating')),
  readMore: loadable(() => import('@e/read-more-card')),
  prosAndCons: loadable(() => import('@c/pros-and-cons')),
  accordion: loadable(() => import('@c/accordion')),
  author: {
    component: Author,
    wrapperConfig: { useContainer: false },
  },
  profile: loadable(() => import('@c/profile')),
  breadcrumb: {
    component: loadable(() => import('@m/breadcrumb-module')),
    wrapperConfig: { spacingTop: 'sm' },
    hydrate: false,
  },
  clickOutForm: loadable(() => import('@c/click-out-form')),
  categoryGuidesModule: {
    component: loadable(() => import('@m/category-guides-module')),
    wrapperConfig: { spacingTop: 'lg', spacingBottom: 'lg' },
    hydrate: true,
  },
  contentHeroModule: {
    component: loadable(() => import('@m/content-hero-module.connected')),
    wrapperConfig: { useContainer: false },
  },
  copyModule: loadable(() => import('@c/copy')),
  cta: {
    component: loadable(() => import('@uswitch/trustyle.cta')),
    hydrate: false,
  },
  linkWithLabel: loadable(() => import('@c/link-with-label')),
  embeddedAsset: loadable(() => import('@e/embedded-asset')),
  energySupplierModule: {
    component: loadable(() => import('@c/logobar')),
    wrapperConfig: { useContainer: false },
  },
  energyHeroModule: {
    component: loadable(() => import('@c/hero')),
    wrapperConfig: { useContainer: false },
  },
  energyAssuranceModule: {
    component: loadable(() => import('@m/assurance-bar')),
    wrapperConfig: { useContainer: false },
  },
  energyAssuranceListModule: {
    component: loadable(() => import('@c/assurance-text')),
    wrapperConfig: { useContainer: false },
  },
  figureModule: loadable(() => import('@m/figure')),
  formInputText: loadable(() => import('@c/form-input-text')),
  formInputEmail: loadable(() => import('@c/form-input-email')),
  formInputSubmit: loadable(() => import('@c/form-input-submit')),
  gridColumnModule: loadable(() => import('@c/grid-column')),
  gridRowModule: loadable(() => import('@c/grid-row')),
  headingModule: loadable(() => import('@c/heading')),
  imageCalloutModule: {
    component: loadable(() => import('@m/image-callout-module')),
    wrapperConfig: { useContainer: false },
  },
  readMoreModule: {
    component: loadable(() => import('@m/read-more-module')),
    wrapperConfig: { useContainer: false },
  },
  iframeModule: {
    component: loadable(() => import('@c/iframe')),
    wrapperConfig: { useContainer: false, spacingBottom: '1.5em' },
  },
  richTextModule: {
    component: loadable(() => import('@m/rich-text')),
    wrapperConfig: { useContainer: false, spacingBottom: 'lg', childSpacing: true },
  },
  richTextBlock: {
    component: loadable(() => import('@e/rich-text-block')),
    wrapperConfig: { childSpacing: true },
  },
  collection: {
    component: collection,
    // Temporary until deprecated spacing options are removed
    wrapperConfig: { noWrapper: true },
  },
  collectionModule: {
    component: collection,
    wrapperConfig: { noWrapper: true },
  },
  cardAwardNominee: loadable(() => import('@c/card-award-nominee')),
  cardAwardWinner: loadable(() => import('@c/card-award-winner')),
  testimonialCard: loadable(() => import('@c/testimonial-card')),
  video: loadable(() => import('@c/video')),
  listItem: loadable(() => import('@uswitch/trustyle.list/list-item')),
  list: loadable(() => import('@uswitch/trustyle.list/list')),
  imageModule: {
    component: loadable(() => import('@m/image-module')),
    wrapperConfig: { useContainer: false, spacingBottom: 'md' },
    hydrate: false,
  },
  callOut: {
    component: loadable(() => import('@uswitch/trustyle.call-out')),
    wrapperConfig: { spacingTop: 'xs', spacingBottom: 'md' },
    hydrate: false,
  },
  categoryHeadingModule: {
    component: loadable(() => import('@m/category-heading-module')),
    wrapperConfig: { useContainer: false },
    hydrate: false,
  },
  table: {
    component: loadable(() => import('@c/table')),
    hydrate: false,
  },
  linkList: {
    component: loadable(() => import('@c/link-list')),
    hydrate: true,
  },
  embeddedVideo: {
    component: loadable(() => import('@e/embedded-video')),
    wrapperConfig: { useContainer: false },
  },
  articleIntro: {
    component: loadable(() => import('@c/article-intro')),
    wrapperConfig: { useContainer: false },
    hydrate: false,
  },
  card: {
    component: loadable(() => import('@c/card')),
    hydrate: false,
  },
  cookieToggle: loadable(() => import('@c/cookie-toggle')),
  authorGuidesModule: {
    component: loadable(() => import('@m/category-guides-module')),
    wrapperConfig: { spacingTop: 'lg', spacingBottom: 'lg' },
    hydrate: false,
  },
  authorProfile: {
    component: loadable(() => import('@m/author-profile')),
    wrapperConfig: { useContainer: false, spacingTop: 'xxl' },
    hydrate: false,
  },
  button: {
    component: loadable(() => import('@e/button')),
    hydrate: true,
  },
  hero: {
    component: HeroModule,
    wrapperConfig: { useContainer: false },
  },
  heroCard: {
    component: loadable(() => import('@c/hero-card')),
  },
  iconTile: {
    component: loadable(() => import('@e/icon-tile')),
    hydrate: false,
  },
  widgetHtml: {
    component: loadable(() => import('@e/widget-html')),
    hydrate: false,
  },
  productTable: {
    component: loadable(() => import('@m/product-table.connected')),
    wrapperConfig: { useContainer: false },
  },
  leadCaptureForm: loadable(() => import('@m/lead-capture-form')),
  navbar: loadable(() => import('@m/navbar')),
  vacancies: loadable(() => import('@m/vacancies')),
  supplierRow: loadable(() => import('@c/supplier-row')),
  form: loadable(() => import('@m/form')),
  person: loadable(() => import('@c/person')),
  sponsoredByTag: loadable(() => import('@m/sponsored-by')),
  countdown: loadable(() => import('@c/countdown')),
}

const paletteHeading = as => (node, text) => (
  <DontHydrate>
    <Palette
      as={as}
      px={{ color: palette => palette.headingColor || palette.textColor }}
      id={toKebabCase(documentToPlainTextString(node) || '')}
    >
      {text}
    </Palette>
  </DontHydrate>
)

export const Options = (options = {}) => {
  const { observedWaypoints, ...extraData } = options
  return {
    renderMark: {
      [M.BOLD]: text => <Styled.b>{text}</Styled.b>,
      [M.ITALIC]: text => <Styled.i>{text}</Styled.i>,
      [M.UNDERLINE]: text => <span sx={{ textDecoration: 'underline' }}>{text}</span>,
      [M.CODE]: text => <Styled.strong>{text}</Styled.strong>,
    },
    renderNode: {
      [B.EMBEDDED_ASSET]: node =>
        moduleEntity(node.data.target, { ...extraData, tag: 'embeddedAsset' }),
      [B.EMBEDDED_ENTRY]: node => moduleEntity(node.data.target, extraData),
      [B.HEADING_1]: paletteHeading(Styled.h1),
      [B.HEADING_2]: (node, text) => {
        const id = toKebabCase(documentToPlainTextString(node) || '')
        return (
          <DontHydrate>
            <Palette
              as={Styled.h2}
              px={{ color: palette => palette.headingColor || palette.textColor }}
              {...(observedWaypoints && observedWaypoints[id]
                ? { ref: observedWaypoints[id][1] }
                : {})}
              id={id}
            >
              {text}
            </Palette>
          </DontHydrate>
        )
      },
      [B.HEADING_3]: paletteHeading(Styled.h3),
      [B.HEADING_4]: paletteHeading(Styled.h4),
      [B.HEADING_5]: paletteHeading(Styled.h5),
      [B.HEADING_6]: paletteHeading(Styled.h6),
      [B.HR]: () => (
        <DontHydrate>
          <Styled.hr />
        </DontHydrate>
      ),
      [B.UL_LIST]: (node, text) => (
        <DontHydrate>
          <Components.list listType='bullet'>{text}</Components.list>
        </DontHydrate>
      ),
      [B.OL_LIST]: (node, text) => (
        <DontHydrate>
          <Components.list listType='numeric'>{text}</Components.list>
        </DontHydrate>
      ),
      [B.LIST_ITEM]: (node, text) => <Components.listItem>{text}</Components.listItem>,
      [I.HYPERLINK]: (node, text) => (
        <DontHydrate>
          <Filter when={text.join('').length}>
            <Link {...extraData} href={node.data.uri}>
              {text}
            </Link>
          </Filter>
        </DontHydrate>
      ),
      [B.PARAGRAPH]: (node, text) => (
        <DontHydrate>
          <Filter when={text.join('').length}>
            <Palette as='p' sx={{ variant: 'styles.p' }} px={{ color: 'textColor' }}>
              <For each='content' index='i' of={text}>
                {content}
                <If condition={typeof content === 'string' && content.endsWith('\n')}>
                  <br />
                </If>
              </For>
            </Palette>
          </Filter>
        </DontHydrate>
      ),
      [B.QUOTE]: (node, text) => <Styled.blockquote>{text}</Styled.blockquote>,
    },
  }
}
