import querystring from 'querystring'

const CANONICAL_PARAMS = ['page']
const getCanonicalParams = url => {
  try {
    const { search } = new URL(url)

    const params = querystring.parse(search.slice(1))
    const canonicalParams = Object.entries(params)
      .filter(([key]) => CANONICAL_PARAMS.indexOf(key) > -1)
      .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {})

    return querystring.stringify(canonicalParams)
  } catch (e) {
    return null
  }
}

export const robotsContent = ({ robotsMeta = ['all'] }) => robotsMeta.join(',').trim()

export const canonicalHref = ({ canonical, href }) => {
  const url = new URL(canonical || href)

  url.protocol = 'https:'
  url.search = getCanonicalParams(canonical) || getCanonicalParams(href)

  return url.toString()
}
