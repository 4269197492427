import React from 'react'
import loadable from '@loadable/component'

import sw from '@u/switch'

const LoadableBg = loadable(() => import('@m/hero-with-background-img.connected'))
const LoadableHero = loadable(() => import('@m/hero.connected'))
const LoadableHeroSecondary = loadable(() => import('@m/hero-secondary.connected'))
const LoadableBbdeals = loadable(() => import('@c/hero-bbdeals'))

const Component = props =>
  sw({
    'Money homepage': props => (
      <LoadableHero
        {...props}
        imagePosition={{
          position: {
            bottom: 0,
            right: 0,
            transform: 'scale(1.4) translate(40%, 5%)',
          },
          imageType: 'img',
          displayOnMobile: true,
        }}
      />
    ),
    'Background Image': props => <LoadableBg {...props} />,
    'Bbdeals Bottom Image': props => <LoadableBbdeals {...props} />,
    Uswitch: props => (
      <LoadableHero
        {...props}
        imagePosition={{
          position: {
            backgroundSize: ['auto 100%'],
            backgroundPosition: ['right 0 bottom 0'],
          },
          imageType: 'background',
          displayOnMobile: props.displayImageOnMobile,
        }}
      />
    ),
    'Hero Secondary': props => <LoadableHeroSecondary {...props} />,
    default: props => <LoadableBg {...props} />,
  })(props.imagePosition, props)

export default Component
