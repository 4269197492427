import React from 'react'

export default ({ when, children }) => (
  <Choose>
    <When condition={when}>{children}</When>
    <Otherwise>
      <></>
    </Otherwise>
  </Choose>
)
