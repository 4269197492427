import { useSelector } from 'react-redux'
import { getModuleFromIncludes, includes } from '@r/contentful-selectors'
import { contentId } from '@u/contentful'

export const useEntity = entity =>
  useSelector(state => {
    const id = typeof entity === 'string' ? entity : contentId(entity)
    const { fields, sys } = getModuleFromIncludes(includes(state), id)
    return { ...fields, sys }
  })
