import connect from '@h/connect-redux'

import { articleSchema, contentTypeId, getAuthorName } from '@u/contentful'
import { path } from '@u/obj'

import ArticleSchema from '@c/article-schema'

const createAuthorObject = (fields, orgName, author) => {
  const name = getAuthorName(author) || orgName
  const type = name === orgName ? 'Organization' : 'Person'

  return { '@type': type, name }
}

const createImageObject = fields => {
  const file = path(['metaImage', 'fields', 'file'], fields)
  if (!file) return {}

  return {
    '@type': 'ImageObject',
    url: file.url,
    width: path(['details', 'image', 'width'], file, ''),
    height: path(['details', 'image', 'height'], file, ''),
  }
}

export default connect((state, { page, meta, url }) => {
  // if page does not have article schema, return empty props
  if (!articleSchema(page)) return {}

  const includes = path(['contentful', 'ctx', 'includes'], state, {})
  const publisherName = path(['articleSchema', 'publisher', 'name'], meta, meta.brand)
  const author = Object.values(includes).filter(mod => contentTypeId(mod) === 'author')[0]

  const fields = page.fields || {}

  const { updated, created } = state.contentful?.dates || {}
  const { updatedDate, publishDate } = state.app?.dates || {}

  return {
    context: 'http://schema.org',
    type: 'Article',
    headline: fields.title,
    description: fields.metaDescription,
    image: createImageObject(fields),
    datePublished: publishDate || created || fields.publishedAt || page.sys.createdAt,
    dateModified: updatedDate || updated || page.sys.updatedAt,
    author: createAuthorObject(fields, meta.brand, author),
    publisher: {
      '@type': 'Organization',
      name: publisherName,
      logo: {
        '@type': 'ImageObject',
        url: meta.favicon.href,
        width: '48',
        height: '48',
      },
    },
    mainEntityOfPage: { '@type': 'WebPage', '@id': url.href },
  }
})(ArticleSchema)
