import React, { Fragment } from 'react'
import { Styled } from 'theme-ui'

import connect from '@h/connect-redux'
import { Helmet } from 'react-helmet-async'
import { useEntity } from '@h/use-entity'
import { cf2imgix } from '@h/imgix-image'

import loadable from '@loadable/component'

import { env, meta } from '@r/app-selectors'
import { breadcrumb, getModuleFromIncludes } from '@r/contentful-selectors'
import { ModularPage } from '@p/modular-page'
import { RichPage } from '@p/rich-page'
import { contentId, contentTypeId, pageTitle } from '@u/contentful'
import { robotsContent, canonicalHref } from '@h/meta-tags'

import Container from '@c/container'
import ArticleSchema from '@c/article-schema.connected'
import BreadcrumbSchema from '@c/breadcrumb-schema'
import ModuleMarker from '@h/module-marker'

import getWindow from '@u/get-window'
import sw from '@u/switch'

const MAX_TITLE_LENGTH = 70
const DebugTools = loadable(() => import('@u/debug-tools'))

const richPage = (fields, includes) =>
  RichPage(fields, { publishedAt: fields.publishedAt, includes })
const modularPage = (fields, includes) => ModularPage(fields, { includes })

const Main = ({ breadcrumb, env, meta, urlModule, includes }) => {
  const linkedPageId = contentId(urlModule.link)
  const linkedPage = getModuleFromIncludes(includes, linkedPageId)
  const metaImage = useEntity(linkedPage.fields.metaImage)
  const modules = sw({ modularPage, richPage: (fields, includes) => richPage(fields, includes) })(
    contentTypeId(linkedPage),
    linkedPage.fields,
    includes,
  )

  // Page title construction
  const titleContent = pageTitle(linkedPage)
  const title =
    titleContent.length > MAX_TITLE_LENGTH ? titleContent : `${titleContent} | ${meta.title}`

  return (
    <Fragment>
      <ModuleMarker entity={linkedPage} />

      <Helmet>
        <title>{title}</title>
        <link
          rel='icon'
          type={meta.favicon.type}
          href={meta.favicon.href}
          sizes={meta.favicon.sizes}
        />
        <link href={canonicalHref(urlModule)} rel='canonical' />
        <link
          rel='icon'
          type={meta.favicon.type}
          href={meta.favicon.href}
          sizes={meta.favicon.sizes}
        />
        <link href='//uswitch-cms.imgix.net' rel='dns-prefetch' />
        <link href='https://uswitch-cms.imgix.net' rel='preconnect' crossorigin />
        <link href='//images.ctfassets.net' rel='dns-prefetch' />
        <link href='https://images.ctfassets.net' rel='preconnect' crossorigin />

        <meta name='robots' content={robotsContent(urlModule)} />
        <meta name='description' content={linkedPage.fields.metaDescription} />
        <meta property='og:title' content={title} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={canonicalHref(urlModule)} />
        <If condition={metaImage.sys}>
          <meta property='og:image' content={cf2imgix(metaImage.file.url)} />
        </If>
      </Helmet>
      <ArticleSchema page={linkedPage} url={urlModule} meta={meta} />
      <BreadcrumbSchema breadcrumb={breadcrumb} url={urlModule} />
      <Styled.root>
        <If
          condition={
            getWindow().location &&
            getWindow().location.search.includes('debug-tools') &&
            env === 'development'
          }
        >
          <DebugTools />
        </If>

        <Choose>
          <When condition={linkedPage.container}>
            <Container>{modules}</Container>
          </When>
          <Otherwise>{modules}</Otherwise>
        </Choose>
      </Styled.root>
    </Fragment>
  )
}

export default connect(state => ({
  breadcrumb: breadcrumb(state),
  env: env(state),
  meta: meta(state),
  urlModule: { ...state.contentful.url, ...state.contentful.ctx.items[0].fields },
  includes: state.contentful.ctx.includes,
}))(Main)
