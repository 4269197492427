import React from 'react'
import { ContentfulModule } from '@h/modules'
import { PaletteProvider } from '@uswitch/trustyle-utils.palette'
import { ModuleWrapperContext } from '@h/module-wrapper'
import { paletteWrapper } from '@h/smart-container'

export const ModularPage = ({ modules = [], extras }, { includes }) => (
  <For each='entity' index='index' of={paletteWrapper(modules, includes)}>
    <ModuleWrapperContext.Provider
      value={{
        alreadyWrapped: false,
        spacingTop: entity.spacingOverride && entity.spacingOverride.top,
        spacingBottom: entity.spacingOverride && entity.spacingOverride.bottom,
      }}
      key={index}
    >
      <PaletteProvider value={entity.palette}>
        <ContentfulModule props={{ entity }} {...extras} />
      </PaletteProvider>
    </ModuleWrapperContext.Provider>
  </For>
)
