/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import Imgix from 'react-imgix'
import querystring from 'querystring'

const defaultImgixParams = {
  auto: 'format,compress',
}

export const cf2imgix = (cfUrl = '', params = {}) => {
  const url = cfUrl.replace(
    /(\/\/downloads.ctfassets.net\/)|(\/\/images.ctfassets.net\/)/g,
    'https://uswitch-contentful.imgix.net/',
  )

  return url ? `${url}?${querystring.stringify(params)}` : ''
}

const pxToInt = input => parseInt(String(input).replace('px', ''))

const ImgixImage = ({
  src,
  width,
  height,
  sizes,
  alt,
  className = '',
  itemProp,
  critical = false,
  sx,
  imgixParams = {},
  ...props
}) => {
  if (!src) {
    return (
      <div
        className={className}
        sx={{
          backgroundColor: 'light-2',
          display: 'inline-block',
        }}
      ></div>
    )
  }

  if (src.endsWith('.svg')) {
    if (typeof critical !== 'undefined') {
      console.warn("Lazy loading isn't supported with SVG images")
    }

    return (
      <img
        src={cf2imgix(src)}
        alt={alt}
        className={className}
        height={height && pxToInt(height)}
        width={width && pxToInt(width)}
        {...{ ...props, dispatch: undefined }}
      />
    )
  }

  return (
    <Imgix
      htmlAttributes={{ alt, itemProp }}
      attributeConfig={
        !critical && {
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        }
      }
      className={`${!critical ? 'lazyload ' : ''}${className}`}
      src={cf2imgix(src)}
      height={height && pxToInt(height)}
      width={width && pxToInt(width)}
      sizes={sizes}
      sx={{ display: 'block', ...sx }}
      imgixParams={{ ...defaultImgixParams, ...imgixParams }}
      {...props}
    />
  )
}

export default React.memo(ImgixImage)
