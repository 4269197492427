import { BLOCKS as B } from '@contentful/rich-text-types'
import { path } from '@u/obj'
import { getModuleFromIncludes } from '@r/contentful-selectors'
import { contentId, contentTypeId } from '@u/contentful'

const last = arr => arr.slice(-1)[0]

const emptyLastContainer = lastContainer =>
  lastContainer.length === 1 &&
  lastContainer[0].nodeType === 'paragraph' &&
  lastContainer[0].content.length === 1 &&
  lastContainer[0].content[0].value === ''

const addHexcodesToPalette = ({ fields }, includes) =>
  Object.entries(fields).reduce((acc, [key, value]) => {
    if (key === 'name') {
      return { ...acc, name: value }
    }

    const colorId = contentId(value)
    const colorMod = getModuleFromIncludes(includes, colorId)
    const hex = `#${path(['fields', 'hexcode'], colorMod)}`
    return { ...acc, [key]: hex }
  }, {})

const getSpacingOverrides = fields => [fields.spacing, fields.tabletSpacing, fields.desktopSpacing]

/**
 * Smart container uses AI and Machine Learning techniques to
 * programtically group blocks of elements together and intelligently
 * wrap them in containers to meet modern UX standards.
 *
 * @example
 * // returns [ [ { nodeType: 'foo' } ], [ { nodeType: 'bar' }, { nodeType: 'baz' }, { nodeType: 'bish' } ], [ { nodeType: 'foo' } ] ]
 * smartContainer([ { nodeType: 'foo' }, container: true ], [ { nodeType: 'bar' }, { nodeType: 'baz' }, { nodeType: 'bish' }, container: false ], [ { nodeType: 'foo' }, container: true ], ['foo'])
 *
 * @param {Object[]} content This is a list of content blocks from the contentful rich text document
 * @param {string[]} [list=['embedded-entrytimes']] A white list of types that should not be grouped into containers
 * @returns {Object[]} Returns a list lists that either should or shouldn't render within a container component
 */

export const smartContainer = (content, includes, list = [B.EMBEDDED_ENTRY]) => {
  let palette = {}
  let nextSpacingOverride
  const containers = content
    .reduce(
      (acc, it, i) => {
        const moduleId = path(['data', 'target', 'sys', 'id'], it)
        const module = getModuleFromIncludes(includes, moduleId)

        const isPalette = module && contentTypeId(module) === 'palette'
        if (isPalette) {
          palette = addHexcodesToPalette(module, includes)
        }

        const isSpacingOverride = module && contentTypeId(module) === 'spacingOverride'
        if (isSpacingOverride) {
          let previousSpacingOverride
          const nextModuleId = path(['data', 'target', 'sys', 'id'], content[i + 1])
          const nextModule = getModuleFromIncludes(includes, nextModuleId)
          if (!nextModule || contentTypeId(nextModule) === 'palette') {
            previousSpacingOverride = Object.assign({}, last(acc).spacingOverride, {
              bottom: getSpacingOverrides(module.fields),
            })
            nextSpacingOverride = { top: 0 }
            return [
              ...acc.slice(0, -1),
              Object.assign(last(acc), { spacingOverride: previousSpacingOverride }),
            ]
          } else {
            previousSpacingOverride = Object.assign({ bottom: 0 }, last(acc).spacingOverride)
            nextSpacingOverride = { top: getSpacingOverrides(module.fields) }
          }

          return [
            ...acc.slice(0, -1),
            Object.assign(last(acc), { spacingOverride: previousSpacingOverride }),
            Object.assign([], { spacingOverride: nextSpacingOverride }),
          ]
        }

        const spacingOverride = nextSpacingOverride
        nextSpacingOverride = undefined

        // modelId will be used to get palette from list of includes
        // If model is a palette then update the palette object
        const previous = last(acc)
        const returnVal = list.includes(it.nodeType)
          ? [...acc, Object.assign([it], { container: false, palette, spacingOverride }), []]
          : [
              ...acc.slice(0, -1),
              Object.assign(previous.concat(it), {
                container: true,
                palette,
                spacingOverride: last(acc).spacingOverride || spacingOverride,
              }),
            ]

        return returnVal
      },
      [[]],
    )
    // Filter out empty items, combining spacing overrides where necessary
    .reduce((acc, it) => {
      if (it.length) {
        return [...acc, it]
      }

      const previous = acc[acc.length - 1]

      if (!previous) {
        return acc
      }

      const spacingOverride = Object.assign({}, previous.spacingOverride)

      if (it.spacingOverride && it.spacingOverride.bottom) {
        spacingOverride.bottom = it.spacingOverride.bottom
      }

      return [...acc.slice(0, -1), Object.assign(previous, { spacingOverride })]
    }, [])

  if (emptyLastContainer(last(containers))) {
    containers.pop()
  }
  return containers
}

export const paletteWrapper = (modules, includes) => {
  let palette = {}
  let nextSpacingOverride
  return modules.reduce((acc, it, i) => {
    const module = getModuleFromIncludes(includes, contentId(it))
    if (module && contentTypeId(module) === 'palette') {
      palette = addHexcodesToPalette(module, includes)
      return acc
    }

    if (module && contentTypeId(module) === 'spacingOverride') {
      const previousModule = acc[acc.length - 1]

      if (previousModule && !previousModule.spacingOverride) {
        previousModule.spacingOverride = {}
      }

      const nextModule =
        modules[i + 1] && getModuleFromIncludes(includes, contentId(modules[i + 1]))
      if (!nextModule || contentTypeId(nextModule) === 'palette') {
        if (previousModule) {
          previousModule.spacingOverride.bottom = getSpacingOverrides(module.fields)
        }
        nextSpacingOverride = { top: 0 }
      } else {
        if (previousModule && !previousModule.spacingOverride.bottom) {
          previousModule.spacingOverride.bottom = 0
        }
        nextSpacingOverride = { top: getSpacingOverrides(module.fields) }
      }

      return acc
    }

    const spacingOverride = nextSpacingOverride
    nextSpacingOverride = undefined

    return [...acc, Object.assign({}, it, { palette, spacingOverride })]
  }, [])
}
