import React from 'react'
import { Helmet } from 'react-helmet-async'
import { urlString } from '../helpers/url'

const BreadcrumbSchema = ({ breadcrumb, url }) => {
  if (!breadcrumb || !breadcrumb.length) return <></>
  const schema = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumb.map((crumb, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      name: crumb.fields.displayText,
      item: urlString({ ...url, ...crumb.fields }),
    })),
  })

  return (
    <Helmet>
      <script type='application/ld+json'>{schema}</script>
    </Helmet>
  )
}

export default BreadcrumbSchema
